import {
  ref, // computed, watch, computed, reactive, onMounted,
} from '@vue/composition-api'
import predefinedRangesEnum from '@/enums/predefinedDateRanges.enum.js'

export default function useDateRanges() {
  const date = ref('')
  const today = ref('')
  const currentDay = ref('')

  const startDate = ref('')
  const isCustomDateRange = ref(false)

  const setDateRanges = dateRangeSelection => {
    switch (dateRangeSelection.value) {
      case predefinedRangesEnum.TODAY.value:
        date.value = new Date()
        isCustomDateRange.value = false
        date.value.setHours(0, 0, 0, 1)
        startDate.value = date.value
        break
      case predefinedRangesEnum.CURRENTWEEK.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        currentDay.value = date.value.getDay()
        date.value.setDate(today.value - (currentDay.value || 7))
        date.value.setHours(0, 0, 0, 1)
        startDate.value = date.value
        break
      case predefinedRangesEnum.CURRENTMONTH.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(date.value.getMonth())
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        startDate.value = date.value
        break
      case predefinedRangesEnum.CURRENTQUARTER.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(date.value.getMonth() - (date.value.getMonth() % 3))
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        startDate.value = date.value
        break
      case predefinedRangesEnum.CURRENTYEAR.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(0)
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        startDate.value = date.value
        break
      case predefinedRangesEnum.LAST7DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 7)
        startDate.value = date.value
        break
      case predefinedRangesEnum.LAST30DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 30)
        startDate.value = date.value
        break
      case predefinedRangesEnum.LAST90DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 90)
        startDate.value = date.value
        break
      case predefinedRangesEnum.LAST365DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 365)
        startDate.value = date.value
        break
      default:
        isCustomDateRange.value = true
    }
  }

  return {
    setDateRanges,
    startDate,
    isCustomDateRange,
  }
}
