<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 px-0">
          <dx-data-grid
              id="sortedBatchesGrid"
              ref="sortedBatchesGridRef"
              :data-source="SortedBatchesDataSource"
              :height="setHeight"
              :cache-enabled="false"
              :allow-column-reordering="true"
              :allow-column-resizing="true"
              :column-auto-width="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :show-borders="true"
              :hover-state-enabled="true"
              :row-alternation-enabled="false"
              @option-changed="onOptionChanged"
              @initialized="onInitialized"
              @content-ready="onContentReady"
              @toolbar-preparing="onToolbarPreparing($event)"
              @row-expanding="onRowExpanding"
          >
            <dx-sorting mode="single" />
            <!-- <dx-search-panel :visible="true" :width="200" placeholder="Search..." /> -->
            <dx-search-panel :highlight-search-text="true" :visible="true" :search-visible-columns-only="true" :width="200" />
            <dx-column data-field="batchName" alignment="left" />
            <dx-column data-field="accountNo" alignment="left" :visible="isServingTenant" />
            <dx-column data-field="suiteNo" alignment="left" :visible="isServingTenant" />
            <dx-column data-field="companyName" :visible="isServingTenant" />
            <dx-column data-field="packageQuantity" caption="Box Qty" alignment="right" />
            <dx-column data-field="itemQuantity" caption="Sorted Qty" alignment="right" />
            <dx-column data-field="accountManager" :visible="isServingTenant" />
            <dx-column v-if="hasPermission" data-field="sortedBy" />
            <dx-column data-field="sortedAt" data-type="date" />
            <dx-column v-if="hasPermission" data-field="id" caption="Actions" alignment="center" :width="120" cell-template="itemActions" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div>
                <dx-util-button type="success" text="Complete" hint="Complete Sort" @click="onCompleteSort(data)" />
              </div>
            </template>
            <dx-remote-operations :paging="true" :filtering="false" :sorting="false" :summary="false" />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <template #toolbarFilters>
              <div class="d-flex flex-row align-items-center">
                <div v-if="hasPermission" class="mr-1">
                  <dx-util-text-box
                    v-if="isServingTenant"
                    v-model="accountNo"
                    :show-clear-button="true"
                    mode="text"
                    placeholder="Account or Suite No"
                    @key-down="onFilterByAccountNo"
                    @value-changed="onFilterByAccountNo"
                  />
                </div>
                <!-- <div v-if="hasPermission" class="mr-1">
                  <div class="dx-field">
                    <div class="dx-field-label">
                      Acc Manager
                    </div>
                    <div class="dx-field-value">
                      <dx-util-select-box
                        v-model="selectedAccountManager"
                        :data-source="accountManagers"
                        display-expr="text"
                        value-expr="value"
                        @value-changed="searchByFilter"
                      />
                    </div>
                  </div>
                </div> -->
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedDateRange"
                    :data-source="dateRangesList"
                    :drop-down-options="{width: '180px'}"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="setDateRanges"
                  />
                </div>
                <div v-show="isCustomDateRange" class="col px-half">
                  <dx-util-date-box
                    v-model="filters.beginDate"
                    :max="filters.endDate"
                    type="date"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div v-show="isCustomDateRange" class="col px-half">
                  <dx-util-date-box
                    v-model="filters.endDate"
                    :min="filters.beginDate"
                    type="date"
                    @value-changed="searchByFilter"
                  />
                </div>
              </div>
            </template>
            <dx-master-detail :enabled="true" template="masterDetailTemplate" />
            <template #masterDetailTemplate="{ data: items }">
              <sorted-batch-items :items="items.data" />
            </template>
          </dx-data-grid>
        </div>
      </div>
    </div>
</template>

<script>
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import useCurrentUser from '@/libs/app/current-user'
import sortItemService from '@/http/requests/inbound/sort-item.service'
import ItemSortFilter from '@/http/models/search-filters/ItemSortFilter'
import userManagementService from '@/http/requests/system/userManagementService'
import { confirm } from 'devextreme/ui/dialog'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import { SortedBatchesDataSource } from './sortedBatchesStore'
import SortedBatchItems from './SortedBatchItems.vue'
import itemSortStatusEnum from '../item-sort/item-sort-status.enum'

export default {
  components: {
    SortedBatchItems,
  },
  mixins: [GridBase, Filters],
  setup() {
    const {
      userId,
      userWarehouseId,
      isServingTenant,
    } = useCurrentUser()
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return {
      userId,
      userWarehouseId,
      setDateRanges,
      startDate,
      isCustomDateRange,
      isServingTenant,
    }
  },
  data() {
    const firstDayOfMonth = new Date()
    const plusOneDay = new Date()
    firstDayOfMonth.setDate(1)
    firstDayOfMonth.setHours(0, 0, 0, 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)

    return {
      SortedBatchesDataSource,
      filters: {
        query: '',
        status: itemSortStatusEnum.OPEN.value,
        beginDate: firstDayOfMonth,
        endDate: plusOneDay,
      },
      selectedAccountManager: 0,
      accountManagers: [],
      selectedDateRange: predefinedDateRangesEnum.CURRENTMONTH.value,
      dateRangesList: getPredefinedDateRangesList(),
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.sortedBatchesGridRef.instance
      return grid
    },
    warehouseId() {
      return this.userWarehouseId
    },
    hasPermission() {
      return this.$can('read', 'resource_sorted_batches_account_managers')
    },
  },
  watch: {
    selectedDateRange() {
      const plusOneDay = new Date()
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.filters.beginDate = this.startDate || this.filters.beginDate
    },
  },
  mounted() {
    this.getAllAccountManagers()
    this.loadData()
  },
  beforeDestroy() {
    this.dataGrid.dispose()
    this.dataGrid.remove()
  },
  methods: {
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        SortedBatchesDataSource.pageIndex(0)
        SortedBatchesDataSource.reload()
      }
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.searchByFilter()
      }
    },
    loadData() {
      ItemSortFilter.setDefaultFilters()
      ItemSortFilter.warehouseId = this.warehouseId
      ItemSortFilter.status = this.filters.status
      ItemSortFilter.itemAction = this.filters.itemAction
      ItemSortFilter.userId = this.selectedAccountManager
      ItemSortFilter.beginDate = this.filters.beginDate
      ItemSortFilter.endDate = this.filters.endDate
      const filters = ItemSortFilter.getFilters()
      SortedBatchesDataSource.searchValue(filters)
      SortedBatchesDataSource.load()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.length = 0
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.dataGrid.refresh().then(() => {
              this.loadData(false)
            })
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
    },
    searchByFilter(e) {
      ItemSortFilter.setDefaultFilters()
      ItemSortFilter.accountNo = this.accountNo
      ItemSortFilter.warehouseId = this.warehouseId
      ItemSortFilter.status = this.filters.status
      ItemSortFilter.itemAction = this.filters.itemAction
      ItemSortFilter.userId = this.selectedAccountManager
      ItemSortFilter.beginDate = this.filters.beginDate
      ItemSortFilter.endDate = this.filters.endDate
      const filters = ItemSortFilter.getFilters()
      SortedBatchesDataSource.searchValue(filters)
      SortedBatchesDataSource.load()
    },
    getAllAccountManagers() {
      this.accountManagers.splice(0, this.accountManagers.length)
      this.accountManagers.push({
        value: 0,
        text: 'Acc Managers (All)',
      })
      userManagementService.fetchAllAccountManagers().then(result => {
        const data = result.data
        data.forEach(element => {
          this.accountManagers.push({
            value: element.id,
            text: element.fullName,
          })
        })
      })
    },
    onCompleteSort(e) {
      const batchId = e.row.data.id
      const confirmResult = confirm(
        'Are you sure you want to complete this batch.',
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          sortItemService.setSortCompleted(batchId).then(() => {
            SortedBatchesDataSource.reload()
          })
        }
      })
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1)
    },
  },
}
</script>

<style></style>
