var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dx-data-grid',{ref:"sortedBatcheItemsGridRef",attrs:{"id":"sortedBatcheItemsGrid","data-source":_vm.dataSource,"show-borders":true,"show-row-lines":true,"column-auto-width":true,"customize-columns":_vm.customizeColumns},on:{"cell-dbl-click":_vm.onCellDblClick},scopedSlots:_vm._u([{key:"itemActionCell",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"d-block badge",class:_vm.getItemActionFormated(data.value).variant},[_vm._v(" "+_vm._s(_vm.getItemActionFormated(data.value).text)+" ")])]}},{key:"imageCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('p-lazy-img',{attrs:{"width":"72px","height":"72px","src":data.value || _vm.defaultImg}})]}},{key:"titleCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"dx-product-title"},[_vm._v(" "+_vm._s(data.value)+" ")])]}},{key:"asinCellTemplate",fn:function(ref){
var data = ref.data;
return [_c('a',{attrs:{"target":"_blank","href":("https://www.amazon.com/gp/product/" + (data.value))}},[_vm._v(" "+_vm._s(data.value)+" ")])]}}])},[_c('dx-column',{attrs:{"data-field":"itemAction","caption":"Action","alignment":"center","cell-template":"itemActionCell"}}),_c('dx-column',{attrs:{"data-field":"smallImage","cell-template":"imageCellTemplate","width":100,"caption":"Image","alignment":"center","allow-sorting":false}}),_c('dx-column',{attrs:{"data-field":"title","caption":"Title","cell-template":"titleCellTemplate","min-width":300}}),_c('dx-column',{attrs:{"data-field":"tracking"}}),_c('dx-column',{attrs:{"data-field":"palletId","caption":"Pallet Id"}}),_c('dx-column',{attrs:{"data-field":"orderNumber"}}),_c('dx-column',{attrs:{"data-field":"upcCode","caption":"UPC","width":120}}),_c('dx-column',{attrs:{"data-field":"asin","caption":"ASIN","alignment":"left","width":120,"cell-template":"asinCellTemplate"}}),_c('dx-column',{attrs:{"data-field":"quantity","alignment":"right","width":80}}),_c('dx-column',{attrs:{"data-field":"storeName"}}),(_vm.hasPermission)?_c('dx-column',{attrs:{"data-field":"sortedByName","caption":"Sorted By","alignment":"left"}}):_vm._e(),_c('dx-column',{attrs:{"data-field":"sortedAt","data-type":"date"}})],1),_c('light-box',{attrs:{"items":_vm.images,"index":_vm.index,"effect":'fade'},on:{"close":function($event){_vm.index = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }