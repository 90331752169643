<template>
    <div>
        <dx-data-grid
            id="sortedBatcheItemsGrid"
            ref="sortedBatcheItemsGridRef"
            :data-source="dataSource"
            :show-borders="true"
            :show-row-lines="true"
            :column-auto-width="true"
            :customize-columns="customizeColumns"
            @cell-dbl-click="onCellDblClick"
        >
        <dx-column data-field="itemAction" caption="Action" alignment="center" cell-template="itemActionCell" />
          <template #itemActionCell="{ data }">
            <span class="d-block badge" :class="getItemActionFormated(data.value).variant">
              {{ getItemActionFormated(data.value).text }}
            </span>
          </template>
        <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
        <template #imageCellTemplate="{data}">
          <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
        </template>
        <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" :min-width="300" />
          <template #titleCellTemplate="{data}">
            <div class="dx-product-title">
              {{ data.value }}
            </div>
          </template>
        <dx-column data-field="tracking" />
        <dx-column data-field="palletId" caption="Pallet Id" />
        <dx-column data-field="orderNumber" />
        <dx-column data-field="upcCode" caption="UPC" :width="120" />
        <dx-column data-field="asin" caption="ASIN" alignment="left" :width="120" cell-template="asinCellTemplate" />
        <template #asinCellTemplate="{data}">
          <a target="_blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
            {{ data.value }}
          </a>
        </template>
        <dx-column data-field="quantity" alignment="right" :width="80" />
        <!-- <dx-column data-field="uploadedQty" caption="Uploaded" />
        <dx-column data-field="sortedQty" caption="Total Sorted" />
        <dx-column data-field="shippedQty" caption="Shipped" /> -->
        <dx-column data-field="storeName" />
        <dx-column v-if="hasPermission" data-field="sortedByName" caption="Sorted By" alignment="left" />
        <dx-column data-field="sortedAt" data-type="date" />
      </dx-data-grid>
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import sortItemService from '@/http/requests/inbound/sort-item.service'
import ItemSortFilter from '@/http/models/search-filters/ItemSortFilter'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'light-box': CoolLightBox,
  },
  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const id = this.items.id
    this.getSortedBatchItems(id)
    return {
      dataSource: [],
      batchId: id,
      defaultImg: defaultImageUrl,
      images: [],
      index: null,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_sorted_batches_account_managers')
    },
  },
  methods: {
    customizeColumns(columns) {
      columns.forEach(column => {
        // eslint-disable-next-line no-param-reassign
        column.cssClass = 'vertical-middle'
      })
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    getItemActionFormated(itemAction) {
      if (!itemAction) return { variant: 'badge-light-danger', text: 'Unknown' }
      if (itemAction === 'FBA') return { variant: 'badge-light-success', text: 'FBA' }
      if (itemAction === 'FBM') return { variant: 'badge-light-primary', text: 'FBM' }
      if (itemAction === 'DROPSHIP') return { variant: 'badge-light-warning', text: 'Dropship' }
      if (itemAction === 'FORWARDING') return { variant: 'badge-light-warning', text: 'Forwarding' }
      return { variant: 'badge-light-secondary', text: itemAction }
    },
    getSortedBatchItems(batchId) {
      ItemSortFilter.setDefaultFilters()
      ItemSortFilter.batchId = batchId
      ItemSortFilter.status = 'ALL'
      const filters = ItemSortFilter.getFilters()
      return sortItemService.fetchItemsByQuery(filters, 'page=0&size=1000')
        .then(response => {
          const data = response.data.body.content
          const dataSource = []
          data.forEach(dataItem => {
            const item = { ...dataItem }
            dataSource.push(item)
          })
          this.dataSource = [...dataSource]
        })
    },
  },

}
</script>
<style lang="scss">
.dx-datagrid-rowsview .dx-row > .dx-master-detail-cell {
  padding: 10px 0px 10px 10px !important;
}
#sortedBatcheItemsGrid {
  .vertical-middle {
    vertical-align: middle;
  }
}
</style>
